<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <div class="item-div">
        <div>
          <label>姓名：</label>
          <label class="item-value-label">{{ memberInfo.name }}</label>
        </div>
        <div>
          <label>商会标识：</label>
          <label class="item-value-label">{{ memberInfo.typeName }}</label>
        </div>
      </div>
      <div class="company-title-div">公司信息</div>
      <div class="company-item-div" v-for="item in msgArray" :key="item.id">
        <div class="item-div">
          <div>
            <label class="item-title-label">公司名称</label>
            <label class="item-value-label">{{ item.companyName }}</label>
          </div>
          <div>
            <label class="btn-label cursor-el" @click="onOpenItem(item)">{{ item.isOpen ? "收起" : "展开" }}</label>
          </div>
        </div>
        <div v-show="item.isOpen">
          <div class="content-item-div">
            <label class="item-title-label">担任职务</label>
            <label class="item-value-label">{{ item.duties }}</label>
          </div>
          <div class="content-item-div">
            <label class="item-title-label">公司图片</label>
            <label class="img-label" v-for="img in item.img">
              <img :src="`${$imgBaseURL}/${img}`" alt="">
            </label>
          </div>
          <div class="content-item-div">公司简介</div>
          <div class="company-introduction-div">{{ item.introduction }}</div>
        </div>
      </div>
      <div class="operate-div" v-if="isApply">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <div class="text-c">
          <input type="button" value="取消" @click="onCancel">
          <input class="save-input" type="button" value="通过" @click="onPass">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFirmMemberMsg, postFirmApplyAddDel, postFirmApplyAddPass} from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    memberInfo: {
      type: Object,
      default: () => {}
    },
    isApply: {
      type: Boolean,
      default: false
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      msgArray: [],
      tipMsg: ""
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getFirmMemberMsg({memberId: this.memberInfo.id})
        .then(data => {
          if(Array.isArray(data)){
            data.forEach(item => {
              item.isOpen = false;
            });
            this.msgArray = data;
          }
        })
        .catch(error => {
          this.$layer.close(this.layerid);
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("获取成员信息失败");
          }
        });
    },
    onOpenItem(item) {
      item.isOpen = !item.isOpen;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onPass() {
      postFirmApplyAddPass({ id: this.memberInfo.id })
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.setTipsMsg(error.data.msg);
          } else {
            this.setTipsMsg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      justify-content: space-between;
    }
    .item-value-label {
      color: #8c8c8c;
    }
    .company-title-div {
      font-weight: bold;
      margin-top: 15px;
    }
    .company-item-div {
      background-color: #f7f7f7;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      .content-item-div {
        margin-top: 10px;
      }
      .company-introduction-div {
        margin-top: 5px;
        color: #8c8c8c;
      }
      .item-title-label {
        margin-right: 15px;
      }
      .btn-label {
        font-size: 12px;
        color: #ffffff;
        padding: 3px 10px;
        border-radius: 10px;
        background-color: #dbb049;
      }
      .img-label {
        margin-right: 5px;
        img {
          width: 64px;
          height: 64px;
          border-radius: 3px;
          vertical-align: middle;
        }
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        border-radius: 17px;
        background-color: #002345;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>